<template>
	<div>
		<router-view ></router-view>
	</div>
</template>

<script>
export default{
    name: 'App',
	data(){
		return {
			routeKey: 0
		}
	}
}

</script>

<style lang="sass">
  @import "@/sass/index.sass"
</style>
