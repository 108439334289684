import Vue from "vue";
import Vuex from "vuex";

import legal from "@/store/modules/legal";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},

  modules: {
    legal,
  },
});
