const Blog = () => import("@/views/AllBlogs.vue");
const Home = () => import("@/views/Home.vue");
const Error = () => import("@/views/Error.vue");
const About = () => import("@/views/About.vue");
const Researchers = () => import("@/views/Researchers.vue");
const PrivacyPolicy = () => import("@/views/PrivacyPolicy.vue");
const CookiePolicy = () => import("@/views/Cookies.vue");
const Terms = () => import("@/views/Terms.vue");
const Faq = () => import("@/views/Faq.vue");
const ResearcherProfile = () => import("@/views/ResearcherProfile.vue");
const SingleBlogPost = () => import("@/views/blog/PlatformaSrpskiIstrazivaci.vue");

const publicRoutes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/o-platformi/",
    name: "About",
    component: About,
  },
  {
    path: "/istrazivaci/",
    name: "Researchers",
    component: Researchers,
  },
  {
    path: "/istrazivaci/:url",
    name: "researchers",
    component: ResearcherProfile,
  },
  {
    path: "/blog/",
    name: "Blog",
    component: Blog,
  },
  {
    path: "/blog/platforma-srpski-istrazivaci",
    name: "SingleBlogPost",
    component: SingleBlogPost,
  },
  {
    path: "/terms-and-conditions/",
    name: "Terms",
    component: Terms,
  },
  {
    path: "/cookie-policy/",
    name: "CookiePolicy",
    component: CookiePolicy,
  },
  {
    path: "/faq/",
    name: "Faq",
    component: Faq,
  },
  {
    path: "/privacy-policy/",
    name: "Privacy",
    component: PrivacyPolicy,
  },
  {
    path: "*",
    name: "Error",
    component: Error,
  },
];

export default publicRoutes;
