const namespaced = true;

const state = {
        terms: {
            title: "Terms and Conditions",
            data:[
                {
                    title: 'Поштовани,',
                    list:[
                        'Пред вама се налазе Правила и услови коришћења којимa се регулише коришћење интернет презентације и платформе Serbian Researchers (у даљем тексту: Интернет презентација, Интернет платформа или Платформа) (постојеће на интернет адреси: https://www.serbianresearchers.com/). Молимо вас да пажљиво прочитате ова Правила и услове коришћења (у даљем тексту: Правила) имајући у виду да се она примењују на вас, односно на ваше коришћење Платформе.',
                        'Поред Правила, и заједно са њима, примењује се и Политика приватности којом се уређује  прикупљање, обрада, коришћење и заштита личних података, у складу са Законом о заштити података о личности ("Сл. гласник РС", бр. 87/2018).',
                        'Свака посета Платформи или регистрацијом значи да сте упознати, односно прочитали Правила и Политику приватности, разумели предмет њиховог уређивања и да се слажете са њима у целости. Уколико, пак, они за вас нису прихватљиви, молимо вас да не користите Платформу.'
                    ]
                },
                {
                    title: 'ОСНОВНЕ ОДРЕДБЕ',
                    list:[
                        'Организација Serbian Researchers има за циљ умрежавање српских истраживача широм света путем креирања платформе за дељење знања и искуства између појединаца који се баве научно-истраживачким радом у Србији и иностранству. Платформа омогућава повезивање истраживача из различитих респективних сфера и области и доприноси размени идеја и сарадњи између универзитета и компанија. Стога, она је, пре свега, намењена физичким лицима која своје професионалне афинитете реализују радом или сарадњом у академским институцијама, научно-истраживачким институтима и развојно-истраживачким центрима унутар компанија. Такође, Платформи може приступити и свако заинтересовано лице у сврху информисања о темама, радионицама и другим садржајима доступним на њој.',
                        'Интернет презентацијом и платформом администрира и управља Удружење СРПСКИ ИСТРАЖИВАЧИ, које поседује, односно које је носилац одговарајућих права и овлашћења на/у погледу Платформе.',
                        'Приступ Платформи или регистрација на њој заснива се на добровољној основи, те уколико лице предузме наведене радње потврђује да је упознато са Правилима и Политиком приватности, разумело предмет њиховог уређивања и сагласно са њиховом применом.'
                    ]
                }
            ]
        },
        privacy: {
            title: "Privacy Policy"
        }
};
const getters = {
    
};
const actions = {
    
};
const mutations = {
   
};

export default {namespaced, state, getters, actions, mutations}